<template>
  <div>
    <div class="modal-backdrop">
      <div class="modal__dialog">
        <div class="modal__header">
          <slot name="header">
            <div class="modal__body">
              <div class="modal__dialog">
                <div class="modal-header">
                  <div class="title-modal"><h1>Manual Gestor</h1></div>
                  <br />
                  <button type="button" class="btn-close" @click="close">
                    <h3>x</h3>
                  </button>
                </div>
                <div class="modal-body scroll">
                  <div class="content-modal">
                    <div class="col row steps first1">
                      <h1>Paso 1</h1>
                      <div
                        class="mt-4 descriptionPaso col-lg-12 col-12 col-sm-10 col-md-10"
                      >
                        <p>
                          Para ingresar al aplicativo es necesario ingresar el
                          usuario y la contraseña con el numero de
                          identificacion y posteriormente procede al dar cli en
                          "INGRESAR"
                        </p>
                      </div>
                      <div
                        class="imagePaso col-lg-10 col-12 col-sm-10 col-md-10"
                      >
                        <img
                          class="imagePass image-fluid"
                          src="../../../src/assets/Manuales/1.png"
                          alt="..."
                        />
                      </div>
                    </div>
                    <hr class="hr" />
                    <div class="col row steps first1">
                      <h1>Paso 2</h1>
                      <div
                        class="mt-4 descriptionPaso col-lg-12 col-12 col-sm-10 col-md-10"
                      >
                        <p>
                          En la pagina principal va encontrar todos las zonas
                          registradas, con el nombre de la zona y la descripcion
                          de la zona.
                        </p>
                      </div>
                      <div class="imagePaso" style="width: 80%">
                        <img
                          class="imagePass image-fluid"
                          src="../../../src/assets/Manuales/Gestor1.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <hr class="hr" />
                    <div class="col row steps first1">
                      <h1>Paso 3</h1>
                      <div
                        class="mt-4 descriptionPaso col-lg-12 col-12 col-sm-10 col-md-10"
                      >
                        <p>
                          Cuando seleccione una zona, lo va llevar a la vista en
                          la cual tiene en la parte superior derecha un boton de
                          "Listar Zonas" el cual tiene todas las zonas
                          registradas, un buscador. En la parte derecha contiene
                          tres acciones: El "Asignar llave" la cual puede
                          hacerle entrega una llave a un instructor, "Historial
                          llave" puede ver el registro de la llave ,
                          "Verificacion Fisica" en caso que el instructor
                          entregue la llave en la entrada al centro ctpi
                        </p>
                      </div>
                      <div class="imagePaso" style="width: 90%">
                        <img
                          class="imagePass image-fluid"
                          src="../../../src/assets/Manuales/Gestor2.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <hr class="hr" />
                    <div class="col row steps first1">
                      <h1>Paso 4</h1>
                      <div
                        class="mt-4 descriptionPaso col-lg-12 col-12 col-sm-10 col-md-10"
                      >
                        <p>
                          Cuando le de en "Asignar llave" lo va a llevar a la
                          vista la cual tiene en la parte izquierda la imagen
                          del ambiente, la descripcion. En la parte derecha la
                          imagen del usuario, nombre del usuario y el correo
                          electronico, en la parte inferior va encontrar
                          "IDENTIFICACION DEL USUARIO" el cual le va ingresar el
                          numero de identificacion del instructor, y
                          posteriormente le va dar click en la lupa que se
                          encuentra en la parte derecha , luego de esto le da al
                          boton "Asignar llave".
                        </p>
                      </div>
                      <div class="imagePaso" style="width: 80%">
                        <img
                          class="imagePass image-fluid"
                          src="../../../src/assets/Manuales/Gestor3.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <hr class="hr" />
                    <div class="col row steps first1">
                      <h1>Paso 5</h1>
                      <div
                        class="mt-4 descriptionPaso col-lg-12 col-12 col-sm-10 col-md-10"
                      >
                        <p>
                          En la accion "Ver Historial" puede ver todo el
                          registro que se ha hecho como mirar el nombre del
                          instructor, el correo electronico, el numero de
                          identificacion , el codigo de la llave y si la tiene
                          en uso.
                        </p>
                      </div>
                      <div class="imagePaso" style="width: 80%">
                        <img
                          class="imagePass image-fluid"
                          src="../../../src/assets/Manuales/Gestor4.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div></div
          ></slot>
        </div>
      </div>
    </div>
  </div>
</template>
//
<script>
export default {
  name: "admin",
  data() {
    return { modalPoliza: true };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 80%;
  height: 95vh;
  border-radius: 20px;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 8px 4px #007bb8 !important;
  padding: 10px;
  overflow: hidden;
}
.modal-header,
.modal-footer {
  /* padding: 15px; */
  display: flex;
}
.modal-header {
  position: relative;
  border-bottom: 1px solid black !important;
  color: #4aae9b;
  justify-content: space-between;
}
.modal-footer {
  border-top: 1px solid black !important;
  flex-direction: column;
  justify-content: flex-end;
}
.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 25px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}
.title-modal {
  text-align: center;
}
.scroll {
  overflow-y: scroll;
  border: 1px solid;
  height: 84vh;
  scrollbar-color: yellow #800080;
  transition: step-end;
}
.content-modal {
  /* overflow: hidden; -webkit-transition: all 700ms ease-out; -moz-transition: all 700ms ease-out; */
  transition: all 700ms;
}
.imagePass {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  border-radius: 10px;
}
.imagePaso {
  height: auto;
  width: 70vh;
}
.steps {
  justify-content: space-evenly;
  transition: all 1300ms;
  margin-bottom: 12px;
}
hr.hr {
  color: #ffffff80;
  box-shadow: 0px 0px 8px 2px #007bb8;
}
</style>
