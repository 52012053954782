<template>
  <div>
    <nav class="navbar shadow-none mb-2">
      <div class="container-fluid justify-content-between">
        <div class="d-flex justify-content-between">
          <div class="">
            <button class="btn btn-sm bg-azul-sena" @click="inicio()">
              <span class="d-sm-inline d-none colorrr" style="color: red"
                >Inicio</span
              >
            </button>
          </div>
        </div>
        <div class="d-flex">
          <ul class="navbar-nav">
            <li class="nav-item d-flex align-items-center">
              <router-link
                to="/"
                class="nav-link text-body font-weight-bold px-0"
              >
                <span
                  class="d-sm-inline d-none text-black"
                  @click="deleteSessionStorage()"
                  >Cerrar Sesión</span
                >
                <fa
                  icon="sign-out-alt"
                  class="mx-2 lead text-black moreSizeIcon"
                />
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    deleteSessionStorage() {
      sessionStorage.removeItem("usuario");
    },
    inicio() {
      location.reload();
    },
  },
};
</script>
<style>
.moreSizeIcon {
  font-size: 2.5rem;
}
.colorrr {
  color: red !important;
}
.btn-sm {
  color: red !important;
}
.bg-azul-sena {
  color: red !important;
}
</style>
