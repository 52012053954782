<template>
  <div>
    <div class="col-12 mt-4 pb-3">
      <div class="card">
        <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
          <div
            class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3"
          >
            <h6 class="text-white text-capitalize ps-3">Transferir Llave</h6>
          </div>
        </div>
        <div class="px-0 pb-2">
          <div class="table-responsive p-0">
            <div class="card card-body">
              <div class="row text-center">
                <div class="col">
                  <img
                    loading="lazy"
                    class="imgSize position-relative z-index-2 border-radius-lg"
                    v-lazy="transferirProp.image_key"
                  />
                  <h6 class="ms-2 mt-4 mb-0">{{ transferirProp.code_key }}</h6>
                </div>
                <div class="col" v-if="user.length === 0">
                  <img
                    loading="lazy"
                    class="imgSize position-relative z-index-2 border-radius-lg"
                    src="../../../src/assets/user_transfer.png"
                  />
                </div>
                <div class="col" v-else>
                  <img
                    loading="lazy"
                    class="imgSize position-relative z-index-2 border-radius-lg"
                    v-lazy="user.image_user"
                  />
                  <h6 class="ms-2 mt-4 mb-0">
                    {{ user.name_person + " " + user.last_name }}
                  </h6>
                </div>
              </div>
              <div class="card-body">
                <div v-if="!findUser" class="input-group input-group-dynamic">
                  <input
                    v-model="identificationUser"
                    type="number"
                    class="form-control"
                    placeholder="N° Identificacion"
                  />
                  <button
                    style="
                      border: none;
                      background-color: transparent;
                      outline: none;
                    "
                    v-if="identificationUser.length === 0"
                    class="transparent text-center center"
                    type="text"
                    disabled
                  >
                    <fa icon="search" />
                  </button>
                  <button
                    v-else
                    style="
                      border: none;
                      background-color: transparent;
                      outline: none;
                    "
                    class="transparent text-center center"
                    type="text"
                    @click="getUser(identificationUser)"
                  >
                    <fa icon="search" />
                  </button>
                </div>
                <div v-else-if="findUser" class="center text-center">
                  <div
                    class="spinner-border text-warning text-center center"
                    role="status"
                  ></div>
                </div>
                <div class="text-center" v-if="user.length === 0">
                  <button
                    type="button"
                    class="btn btn-lg bg-gradient-secondary btn-lg w-100 mt-4 mb-0 p-2"
                    disabled
                  >
                    Tranferir Llave
                  </button>
                </div>
                <div class="text-center" v-else>
                  <div class="text-center" v-if="estadopeticion">
                    <AnimationLoading></AnimationLoading>
                  </div>
                  <button
                    v-if="!estadopeticion"
                    type="button"
                    class="btn btn-lg bg-gradient-secondary btn-lg w-100 mt-4 mb-0"
                    @click="tranferirLlave(transferirProp)"
                  >
                    Tranferir Llave
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimationLoading from "@/components/Animations/AnimationLoading.vue";
export default defineComponent({
  components: { AnimationLoading },
  name: "menuInstructor",
  props: { transferirProp: Object },
  data() {
    return {
      estadopeticion: false,
      user: [],
      identificationUser: "",
      findUser: false,
    };
  },
  methods: {
    tranferirLlave(asignacion) {
      let date = new Date();
      let mes = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ];
      let anio = date.getFullYear();
      let hora =
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
      Axios()
        .get("consultar/" + this.transferirProp.id_key_user)
        .then((consulta) => {
          if (consulta.data.results.returned_on != "En uso") {
            Swal.fire({
              title: "Error al tranferir la llave",
              icon: "error",
              text: "Llave no esta en uso",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#008000",
              
            }).then((timeResponse)=>{
              if (timeResponse.isConfirmed) {
               
                location.reload();
              }else{
                location.reload();
              }
            })
          } else {
            Axios()
              .put("actualizar_asignacion/" + asignacion.id_key_user, {
                user_id: asignacion.user_id,
                key_id: asignacion.key_id,
                transfered_on: asignacion.transfered_on,
                returned_on:
                  date.getUTCDate() +
                  "-" +
                  mes[date.getMonth()] +
                  "-" +
                  anio +
                  "-" +
                  hora,
              })
              .then((respuesta) => {
                if (respuesta.data.status == "success") {
                  this.makeAssigment();
                } else {
                  Swal.fire({
                    title: "Error al tranferir la llave",
                    icon: "error",
                    text: "Hubo un problema al tranferir la llave",
                    confirmButtonText: "Aceptar",
                    confirmButtonColor: "#008000",
                  });
                }
              })
              .catch(() => {
                Swal.fire(
                  "ERROR!",
                  "Se ha presentado un error en el servidor!",
                  "error"
                );
              });
          }
        });
    },
    makeAssigment() {
      let date = new Date();
      let mes = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ];
      let hora =
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
      let anio = date.getFullYear();
      Axios()
        .post("almacenar_asignacion", {
          user_id: this.user.id_user,
          key_id: this.transferirProp.id_key,
          transfered_on:
            date.getUTCDate() +
            "-" +
            mes[date.getMonth()] +
            "-" +
            anio +
            "-" +
            hora,
          returned_on: "En uso",
        })
        .then((respuesta) => {
          if (respuesta.data.status == "success") {
            Swal.fire({
              title: "Llave transferida correctamente",
              icon: "success",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#238276",
            }).then((respuesta) => {
              if (respuesta.isConfirmed) {
                location.reload();
              }
            });
          } else {
            Swal.fire({
              title: "Error de Transferencia",
              icon: "error",
              text: "Hubo un problema al realizar la tranferencia",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#008000",
            });
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        })
        .finally(() => {
          this.estadopeticion = true;
        });
    },
    getUser($identification) {
      this.findUser = true;
      Axios()
        .get("lista_usuario/" + $identification)
        .then((response) => {
          if (response.data.status == "success") {
            if (response.data.results.zone_id == this.transferirProp.zone_id) {
              this.user = response.data.results;
            } else {
              this.user = [];
              Swal.fire({
                title: "Error de asignacion ",
                icon: "error",
                text: "No se le puede asignar una llave a un instructor con zonas diferentes",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#008f39",
              });
            }
          } else if (response.data.status == "info") {
            Swal.fire({
              title: "Identificacion no encontrada",
              icon: "error",
              text: "",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#008f39",
            });
            this.user = [];
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        })
        .finally(() => {
          this.findUser = false;
        });
    },
  },
});
</script>
<style>
.imgSize {
  width: 20rem;
  height: 8rem;
}
</style>
