<template>
  <div v-if="!statusLoading" class="container center"><AnimationLoading /></div>
  <div v-if="compMisLlaves">
    <div class="row mt-4 text-center">
      <div
        class="col-12 col-sm-12 col-md-6 col-lg-6 mb-4 text-center"
        v-for="(asignacion, key) in listaAsignaciones"
        :key="key"
      >
        <div class="card p-3 text-center">
          <div class="container">
            <div class="row text-center">
              <div class="col">
                <img
                  loading="lazy"
                  class="imgSize position-relative z-index-2 border-radius-lg"
                  v-lazy="asignacion.image_environment"
                />
                <h6 class="ms-2 mt-4 mb-0">
                  {{ asignacion.name_environments }}
                </h6>
              </div>
              <div class="col">
                <img
                  loading="lazy"
                  class="imgSize position-relative z-index-2 border-radius-lg"
                  v-lazy="asignacion.image_key"
                />
                <h6 class="ms-2 mt-4 mb-0">{{ asignacion.code_key }}</h6>
                {{ asignacion.in_use }}
              </div>
            </div>
          </div>
          <div v-if="asignacion.status_key == 'Verificado'">
            <div class="row">
              <div class="text-center" v-if="estadopeticion">
                <AnimationLoading></AnimationLoading>
              </div>
              <button
                type="button"
                class="btn bg-gradient-info col m-1 p-2"
                @click="
                  ressetValueComponents(),
                    (compTranferirLlave = true),
                    (transferirProp = asignacion)
                "
              >
                Transferir
              </button>
              <button
                type="button"
                class="btn bg-gradient-success col m-1 p-2"
                v-on:click="entregarLLave(asignacion)"
              >
                Entregar
              </button>
            </div>
          </div>
          <div v-if="asignacion.status_key == 'Pendiente'">
            <h5>Estado de la llave</h5>
            <h5 class="mt-3">{{ status_key }}</h5>
            <p class="text-sm font-weight-normal"></p>
            <h6>Acérquese al gestor para verifica la entrega de la llave</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="compTranferirLlave" class="mt-2">
    <button
      type="button"
      class="btn bg-gradient-dark col m-1 p-2 text-center"
      @click="ressetValueComponents(), (compMisLlaves = true)"
    >
      <fa icon="long-arrow-alt-left" class="moreSizeIcon" />
    </button>
    <TransferirLlave :transferirProp="transferirProp" />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import AnimationLoading from "@/components/Animations/AnimationLoading.vue";
import TransferirLlave from "@/components/MenuInstructor/TransferirLlave.vue";
export default defineComponent({
  name: "menuInstructor",
  components: { AnimationLoading, TransferirLlave },
  data() {
    return {
      status_key: " Pendiente",
      estadopeticion: false,
      statusLoading: false,
      listaAsignaciones: [],
      transferirProp: "",
      compTranferirLlave: false,
      compMisLlaves: true,
      status: false,
    };
  },
  methods: {
    ressetValueComponents() {
      this.compTranferirLlave = false;
      this.compMisLlaves = false;
    },
    obtenerAsignaciones() {
      Axios()
        .get("mostrar_asignaciones_por_usuario/" + this.$route.params.id)
        .then((response) => {
          if (response.data.status == "success") {
            this.listaAsignaciones = response.data.results;

            this.statusLoading = true;
            this.llavesAsignadasVacias();
          } else {
            Swal.fire({
              title: "Asignaciones no encontradas",
              icon: "info",
              text: "",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#f27474",
            });
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el serFvidor!",
            "error"
          );
        })
        .finally(() => {
          this.statusLoading = true;
        });
    },
    llavesAsignadasVacias() {
      if (this.listaAsignaciones.length == 0) {
        Swal.fire({
          title: "No tiene llaves asignadas",
          icon: "info",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#238276",
        }).then((respuesta) => {
          if (respuesta.isConfirmed) {
            location.reload();
          }
        });
      }
    },
    entregarLLave(asignacion) {
      Swal.fire({
        title: "Seguro desea entregar la llave?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#00b347",
        denyButtonText: `Don't save`,
        cancelButtonColor: "#d33",
      }).then((respuesta) => {
        if (respuesta.isConfirmed) {
          let date = new Date();
          date.getUTCDate();
          let mes = [
            "enero",
            "febrero",
            "marzo",
            "abril",
            "mayo",
            "junio",
            "julio",
            "agosto",
            "septiembre",
            "octubre",
            "noviembre",
            "diciembre",
          ];
          date.getFullYear();
          date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
          Axios()
            .put("actualizar_asignacion/" + asignacion.id_key_user, {
              user_id: asignacion.user_id,
              key_id: asignacion.key_id,
              transfered_on: asignacion.transfered_on,
              returned_on: "En uso",
            })
            .then((respuesta) => {
              if (respuesta.data.status == "success") {
                this.modifyKey(asignacion);
              } else {
                Swal.fire({
                  title: "Error al entregar llave",
                  icon: "error",
                  text: "Hubo un problema al entregar la llave",
                  confirmButtonText: "Aceptar",
                  confirmButtonColor: "#008000",
                });
              }
            })
            .catch(() => {
              Swal.fire(
                "ERROR!",
                "Se ha presentado un error en el servidor!",
                "error"
              );
            })
            .finally(() => {
              this.estadopeticion = true;
            });
        }
      });
    },
    async modifyKey(asignacion) {
      Axios()
        .put("actualizar_llave/" + asignacion.id_key, {
          environment_id: asignacion.environment_id,
          image_key: asignacion.image_key,
          url_code_qr: asignacion.url_code_qr,
          code_key: asignacion.code_key,
          in_use: "No Disponible",
          status_key: "Pendiente",
        })
        .then((response) => {
          if (response.data.status == "success") {
            Swal.fire({
              title: "Llave entregada correctamente",
              icon: "success",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#238276",
            }).then((confirm) => {
              this.statusLoading = false;
              if (confirm.isConfirmed) {
                location.reload();
              }
            });
          } else {
            Swal.fire({
              title: "Error al entregar llave",
              icon: "error",
              text: "Hubo un problema al entregar la llave",
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#008000",
            });
          }
        })
        .catch(() => {
          Swal.fire(
            "ERROR!",
            "Se ha presentado un error en el servidor!",
            "error"
          );
        });
    },
  },
  async mounted() {
    this.obtenerAsignaciones();
  },
});
</script>
<style>
.imgSize {
  width: 10rem;
  height: 8rem;
}
.moreSizeIcon {
  font-size: 1.5rem;
}
</style>
